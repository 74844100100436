import styled from "styled-components";
import media from "constants/media";
import Title from "components/shared/hero/title/Title";

export const Content = styled.div`
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 40px 0 310px;

    :after {
        position: absolute;
        right: -130px;

        width: 480px;
        height: 1705px;

        content: url("/images/pdp-letters-mobile.svg");
    }

    ${media.mdUp`
        flex-direction: row;

        padding: 30px 0 160px;

        :after {
            position: absolute;
            top: -140px;
            right: -207px;

            width: 920px;
            height: 978px;

            content: url("/images/home-hero-letters.svg");
        }
    `}
`;

export const Left = styled.div`
    position: relative;
    z-index: 3;

    width: 100%;
    max-width: 480px;

    margin: 0 auto;

    text-align: center;

    ${media.mdUp`
        width: 50%;
        max-width: none;

        text-align: left;
    `}
`;

export const Heading = styled(Title)`
    margin-bottom: 20px;

    font-size: 45px;
    line-height: 50px;

    ${media.mdUp`
        margin-bottom: 10px;

        font-size: 90px;
        line-height: 95px;
        text-align:left;
    `}
`;

export const Right = styled.div`
    position: relative;

    width: 100%;

    ${media.mdUp`
        width: 50%;
    `}
`;

export const Background = styled.div`
    position: absolute;
    top: -60px;
    right: 50%;
    z-index: 2;

    width: 587px;
    height: 390px;

    transform: translateX(60%);

    ${media.mdUp`
        top: 0;
        right: -40%;

        width: 140%;

        transform: none;
    `}
`;

export const Letters = styled.div`
    position: absolute;
    top: -375px;
    right: 50%;
    z-index: 1;

    width: 480px;

    transform: translateX(51%);

    ${media.mdUp`
        top: -180px;
        right: 50%;

        width: 920px;

        transform: translateX(55%);
    `}
`;
