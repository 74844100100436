import PropTypes from "prop-types";

import Container from "components/shared/container/Container";

import { MerchantsStyled, Content, Title, Logos } from "./Merchants.styled";

export default function Merchants({ isHomepage }) {
    return (
        <MerchantsStyled>
            <Container>
                <Content>
                    <Title>Lees! Cadeaukaart inwisselen</Title>
                    <Logos isHomepage={isHomepage} />
                </Content>
            </Container>
        </MerchantsStyled>
    );
}

Merchants.propTypes = {
    isHomepage: PropTypes.bool
};

Merchants.defaultProps = {
    isHomepage: false
};
