import PropTypes from "prop-types";
import Link from "next/link";
import { useMediaQueryContext } from "lib/mediaQueryContext";
import Button from "components/shared/button/Button";
import Container from "components/shared/container/Container";
import UspTicker from "components/shared/uspTicker/UspTicker";

import { UspsStyled, Items, Item, Title, Letters, Ticker } from "./Usps.styled";

function Usps({ items }) {
    const { mobileView } = useMediaQueryContext();
    return (
        <UspsStyled>
            <Container>
                <Items>
                    {items.length > 0 ? (
                        items.map(usp => (
                            <Item>
                                <Title
                                    dangerouslySetInnerHTML={{
                                        __html: usp?.title ?? ""
                                    }}
                                />
                                {usp?.link?.url && usp?.link?.label && (
                                    <Link
                                        href={usp?.link?.url}
                                        passHref
                                        prefetch={false}
                                    >
                                        <Button element="a" variant="ghost">
                                            {usp?.link?.label}
                                        </Button>
                                    </Link>
                                )}
                                {!usp?.link?.url && usp?.link?.label && (
                                    <Button element="a" variant="ghost">
                                        {usp?.link?.label}
                                    </Button>
                                )}
                            </Item>
                        ))
                    ) : (
                        <>
                            <Item>
                                <Title>
                                    Eindeloos <strong>personaliseren</strong>
                                </Title>
                                <Link
                                    href="/lees-cadeaukaart-bestellen"
                                    passHref
                                    prefetch={false}
                                >
                                    <Button element="a" variant="ghost">
                                        Personaliseer mijn cadeau
                                    </Button>
                                </Link>
                            </Item>
                            <Item>
                                <Title>
                                    <strong>Boeken</strong>, e-books en
                                    tijdschriften
                                </Title>
                                <Link
                                    href="/inwisselen"
                                    passHref
                                    prefetch={false}
                                >
                                    <Button element="a" variant="ghost">
                                        Bekijk het aanbod
                                    </Button>
                                </Link>
                            </Item>
                            <Item>
                                <Title>
                                    <strong>Gratis verpakt</strong> in een mooie
                                    cadeau envelop
                                </Title>
                                <Link
                                    href="/lees-cadeaukaart-bestellen"
                                    passHref
                                    prefetch={false}
                                >
                                    <Button element="a" variant="ghost">
                                        Kies een speciale verpakking
                                    </Button>
                                </Link>
                            </Item>
                            <Item>
                                <Title>
                                    Kies zelf een <strong>waarde</strong> van €5
                                    tot €150
                                </Title>
                                <Link
                                    href="/saldocheck"
                                    passHref
                                    prefetch={false}
                                >
                                    <Button element="a" variant="ghost">
                                        Check jouw saldo
                                    </Button>
                                </Link>
                            </Item>
                        </>
                    )}
                </Items>
                {mobileView && (
                    <>
                        <Letters />
                        <Ticker>
                            <UspTicker />
                        </Ticker>
                    </>
                )}
            </Container>
        </UspsStyled>
    );
}

Usps.defaultProps = {
    items: []
};

Usps.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            description: PropTypes.string,
            link: PropTypes.shape({
                label: PropTypes.string,
                url: PropTypes.string
            })
        })
    )
};

export default Usps;
