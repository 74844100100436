import styled from "styled-components";

import media from "constants/media";

export const DraggableStyled = styled.div`
    position: relative;

    ${media.mdUp`
        cursor: url("/images/icon-drag.svg") 64 64, grab;
    `}
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;

    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.8);
    visibility: ${({ clickedInside }) =>
        clickedInside ? "hidden" : "visible"};
    opacity: ${({ clickedInside }) => (clickedInside ? "0" : "1")};

    transition: 0.3s all ease-in-out;

    pointer-events: none;

    ${media.mdUp`
        display: none;
    `}
`;

export const Content = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 5;

    text-align: center;

    transform: translate(-50%, -50%);
`;
