import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { useState } from "react";

import { DraggableStyled, Overlay, Content } from "./Draggable.styled";

const ScrollContainer = dynamic(() => import("react-indiana-drag-scroll"));

function Draggable({ item }) {
    const [clickedInside, setClickedInside] = useState(false);

    return item ? (
        <DraggableStyled
            onMouseDown={() => !clickedInside && setClickedInside(true)}
            onTouchStart={() => !clickedInside && setClickedInside(true)}
        >
            <ScrollContainer horizontal>{item}</ScrollContainer>
            <Overlay clickedInside={clickedInside}>
                <Content>
                    <img
                        src="/images/icon-swipe.svg"
                        alt="Swipe door de afbeeldingen"
                        loading="lazy"
                        width="50"
                        height="48"
                    />
                    <p>Swipe</p>
                </Content>
            </Overlay>
        </DraggableStyled>
    ) : null;
}

Draggable.propTypes = {
    item: PropTypes.node.isRequired
};

export default Draggable;
