import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";
import Link from "next/link";
import Draggable from "../draggable/Draggable";
import Button from "../button/Button";

import {
    Content,
    Title,
    Text,
    Books,
    Image,
    Controls
} from "./Acceptants.styled";

function Acceptants({ title, description, buttons }) {
    const { ref, inView } = useInView({
        triggerOnce: true,
        rootMargin: "100px"
    });

    return (
        <div ref={ref}>
            <Content>
                <Title>{title}</Title>
                <Text>{description}</Text>
                {buttons === "acceptants" && (
                    <Link href="/inwisselen" passHref prefetch={false}>
                        <Button element="a" variant="ghost">
                            Bekijk alle acceptanten
                        </Button>
                    </Link>
                )}
                {buttons === "redeem" && (
                    <Controls>
                        <Link href="/saldocheck" passHref prefetch={false}>
                            <Button element="a" variant="ghost">
                                Check mijn saldo
                            </Button>
                        </Link>
                        <Link href="/inwisselen" passHref prefetch={false}>
                            <Button element="a" variant="primary">
                                Inwisselen
                            </Button>
                        </Link>
                    </Controls>
                )}
            </Content>
            <Books>
                {inView && (
                    <Draggable
                        item={
                            <Image
                                src="/images/books.jpg"
                                alt="Voorbeeld producten"
                                loading="lazy"
                                width={2800}
                                height={800}
                                layout="fixed"
                            />
                        }
                    />
                )}
            </Books>
        </div>
    );
}

Acceptants.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    buttons: PropTypes.oneOf(["none", "acceptants", "redeem"])
};

Acceptants.defaultProps = {
    title: "Heb je een Lees! Cadeaukaart ontvangen?",
    description:
        "Gefeliciteerd! Je kunt je gift card spenderen aan verschillende soorten tijdschriften, boeken en e-books. Hier zie je al een aantal populaire tijdschrijften, boeken en e-books. Veel leesplezier! Deze kun je bij onderstaande offline en online winkels aanschaffen.",
    buttons: "redeem"
};

export default Acceptants;
