import styled from "styled-components";
import media from "constants/media";

export const StepsStyled = styled.div`
    max-width: 480px;
    margin: 0 auto;
    padding-bottom: 50px;

    ${media.mdUp`
        max-width: none;
        padding-bottom: 130px;
    `}
`;

export const MobileSlider = styled.div`
    min-height: 415px;

    ${media.mobileOnly}
`;

export const Heading = styled.h2`
    margin-bottom: 25px;

    font-size: 25px;
    font-family: var(--font-DINProCondensed);
    line-height: 30px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;

    ${media.mdUp`
        font-size: 32px;
        line-height: 40px;
    `}
`;

export const ItemMobile = styled.div`
    position: relative;
    z-index: 3;

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 240px;
    height: 100%;
    padding: 45px 30px;

    background: var(--color-white);
    box-shadow: 10px 10px 24px 0 rgba(0, 23, 75, 0.05);
`;

export const Items = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 -10px;

    list-style: none;

    ${media.desktopOnly}
`;

export const Item = styled.li`
    position: relative;
    z-index: 3;

    max-width: 480px;
    margin: 0 10px;
    padding: 65px 50px;

    background: var(--color-white);
    box-shadow: 10px 10px 24px 0 rgba(0, 23, 75, 0.05);
`;

export const Number = styled.div`
    position: absolute;
    top: 10px;
    right: 12px;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;

    color: var(--color-white);
    font-size: 16px;
    line-height: 25px;

    background: var(--color-darkNight);

    ${media.mdUp`
        width: 42px;
        height: 42px;
    `}

    &:before {
        position: absolute;
        top: 2px;
        right: -2px;
        z-index: -1;

        width: 30px;
        height: 30px;

        background: linear-gradient(48.64deg, #ff7871 0%, #b478ff 100%);

        content: "";

        ${media.mdUp`
            width: 42px;
            height: 42px;
        `}
    }
`;

export const Title = styled.h3`
    margin-bottom: 10px;

    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    text-align: center;

    ${media.mdUp`
        font-size: 24px;
        line-height: 30px;
    `}

    strong {
        font-weight: normal;
        font-family: var(--font-Agne);
    }
`;

export const Text = styled.p`
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    ${media.mdUp`
        font-size: 15px;
        line-height: 25px;
    `}
`;
