import PropTypes from "prop-types";
import Container from "components/shared/container/Container";
import { useMediaQueryContext } from "lib/mediaQueryContext";
import UspTicker from "components/shared/uspTicker/UspTicker";
import Breadcrumbs from "components/shared/breadcrumbs/Breadcrumbs";

import { HeroStyled, Top, Content } from "./Hero.styled";

function Hero({
    breadcrumbs,
    children,
    contentWidth,
    background,
    overflowHidden,
    className,
    usps
}) {
    const { desktopView } = useMediaQueryContext();

    return (
        <HeroStyled
            className={className || null}
            overflowHidden={overflowHidden}
        >
            <Container>
                {desktopView && (
                    <Top>
                        {breadcrumbs.length !== 0 && (
                            <Breadcrumbs items={breadcrumbs} />
                        )}
                        <UspTicker type="inverted" usps={usps} />
                    </Top>
                )}

                {contentWidth === "half" && <Content>{children}</Content>}
                {contentWidth === "container" && children}
            </Container>
            {contentWidth === "full" && children}
            {background}
        </HeroStyled>
    );
}

Hero.propTypes = {
    children: PropTypes.node.isRequired,
    background: PropTypes.node,
    contentWidth: PropTypes.oneOf(["half", "container", "full"]),
    breadcrumbs: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string
        })
    ),

    overflowHidden: PropTypes.bool,
    className: PropTypes.string,
    usps: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            link: PropTypes.string
        })
    )
};

Hero.defaultProps = {
    breadcrumbs: [],
    background: undefined,
    contentWidth: "container",
    overflowHidden: true,
    className: undefined,
    usps: undefined
};

export default Hero;
