import dynamic from "next/dynamic";
import PropTypes from "prop-types";
import { useInView } from "react-intersection-observer";

import Container from "components/shared/container/Container";
import {
    StepsStyled,
    MobileSlider,
    Heading,
    ItemMobile,
    Items,
    Item,
    Number,
    Title,
    Text
} from "./Steps.styled";

const Slider = dynamic(() => import("components/shared/slider/Slider"));

export default function Steps({ items }) {
    const { inView, ref } = useInView({
        triggerOnce: true,
        rootMargin: "100px"
    });

    return (
        <StepsStyled>
            <Container>
                <Heading>Zo werkt Lees! Cadeaukaart</Heading>
                <MobileSlider ref={ref}>
                    {inView && (
                        <Slider
                            slideWidth="240px"
                            overflowVisible
                            noButtonsMobile
                            items={items?.map((item, index) => (
                                <ItemMobile key={item.id}>
                                    <Number>{index + 1}</Number>
                                    {item?.title && (
                                        <Title>{item?.title ?? ""}</Title>
                                    )}
                                    {item?.description && (
                                        <Text
                                            dangerouslySetInnerHTML={{
                                                __html: item?.description
                                            }}
                                        />
                                    )}
                                </ItemMobile>
                            ))}
                        />
                    )}
                </MobileSlider>
                <Items>
                    {items?.map((item, index) => (
                        <Item key={item.id}>
                            <Number>{index + 1}</Number>
                            {item?.title && <Title>{item?.title ?? ""}</Title>}
                            {item?.description && (
                                <Text
                                    dangerouslySetInnerHTML={{
                                        __html: item?.description
                                    }}
                                />
                            )}
                        </Item>
                    ))}
                </Items>
            </Container>
        </StepsStyled>
    );
}

Steps.propTypes = {
    items: PropTypes.array
};

Steps.defaultProps = {
    items: [
        {
            id: "1",
            title: (
                <>
                    Bepaal een <strong>waarde</strong>
                </>
            ),
            description:
                "Kies zelf de waarde van jouw verrassing! De ontvanger kan de cadeaukaart in één keer of in delen verzilveren. Meer verrassingen nodig? Je kunt ook meerdere kaarten met verschillende waardes bestellen."
        },
        {
            id: "2",
            title: (
                <>
                    <strong>Persoonlijk</strong> maken
                </>
            ),
            description:
                "Personaliseer jouw cadeau met een persoonlijke boodschap, feestelijke verpakking of een heerlijke fles wijn. Zo maak je van jouw verrassing een echt persoonlijk cadeautje!"
        },
        {
            id: "3",
            title: (
                <>
                    Letterlijk <strong>verrassen</strong>
                </>
            ),
            description:
                "Weet jij niet precies wat voor boek of tijdschrift degene die jij wilt verrassen leuk vind? Geen probleem. Met de Lees! Cadeaukaart kan de ontvanger zelf kiezen uit verschillende tijdschriften, boeken en e-books."
        }
    ]
};
