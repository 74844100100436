import styled from "styled-components";
import media from "constants/media";

export const Title = styled.h2`
    margin: 0 auto 14px;

    font-size: 25px;
    font-family: var(--font-DINProCondensed);
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;

    ${media.mdUp`
        font-size: 32px;
        line-height: 40px;
    `}
`;

export const Text = styled.div`
    max-height: ${({ lines }) => 20 * lines}px;

    margin: 0 auto 14px;
    overflow: hidden;

    font-size: 14px;
    line-height: 20px;

    transition: max-height 0.6s ease;

    ${media.mdUp`
        line-height: 25px;
        font-size: 15px;
        max-height: ${({ lines }) => 25 * lines}px;
    `}
`;

export const Controls = styled.div`
    position: relative;
`;

export const Gradient = styled.div`
    position: absolute;
    top: -100px;
    left: 0;

    width: 100%;
    height: 100px;

    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0) 0%,
        #ffffff 100%
    );

    transform: scaleY(${({ isShowingMore }) => (isShowingMore ? "0.1" : "1")});
    transform-origin: bottom center;

    transition: all 0.6s ease;
`;
