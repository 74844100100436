import styled from "styled-components";
import media from "constants/media";

import StoreLogos from "components/shared/storesLogos/StoresLogos";

export const MerchantsStyled = styled.div`
    position: relative;
    z-index: 3;

    margin-top: -70px;

    ${media.mdUp`
        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 100vw;
            background-color: transparent;
            z-index: -1;
        }
        &:before {
            top: 0;
            left: 0;
            height: 100%;
            transform: translateX(-50%);

        }
        &:after {
            top: 65px;
            right: 0;
            height: 100%;
            transform: translateX(50%);
        }
    `}
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 165px;
    padding: 125px 20px 105px;

    background: var(--color-white);

    box-shadow: 10px 10px 24px 0 rgba(0, 23, 75, 0.05);

    ${media.mdUp`
        width: 90%;
        height: 175px;

        padding: 120px 80px 100px;
    `}
`;

export const Title = styled.h2`
    margin-bottom: 14px;

    font-size: 18px;
    font-family: var(--font-DINProCondensed);
    line-height: 25px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;

    ${media.mdUp`
        margin-bottom: 18px;

        font-size: 24px;
        line-height: 30px;
        letter-spacing: 2px;
    `}
`;

export const Logos = styled(StoreLogos)`
    width: 100%;

    ${media.mdUp`
        margin: 0;
    `}
`;

export const Logo = styled.li`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(33% - 10px);

    margin: 10px 5px;

    ${media.smUp`
        width: 100%;
    `}

    ${media.mdUp`
        flex: 1;
        margin: 0;

        &:not(:last-child) {
            margin-right: 3vw;
        }
    `}
`;

export const Icon = styled.img`
    width: 75px;
    height: 15px;
    margin: 0 auto;

    ${media.smUp`
        width: 100%;
        max-width: none;
        max-height: 18px;
        margin: 0;
    `}

    ${media.mdUp`
        width: 110px;
        height: 25px;
    `}
`;
