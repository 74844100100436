import PropTypes from "prop-types";
import ClampedText from "components/shared/clampedText/ClampedText";
import Container from "components/shared/container/Container";

import { SeoStyled } from "./Seo.styled";

export default function Seo({ title, text }) {
    return (
        <SeoStyled>
            <Container>
                <ClampedText title={title} text={text} />
            </Container>
        </SeoStyled>
    );
}

Seo.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string
};

Seo.defaultProps = {
    title: "Over Lees! Cadeaukaart",
    text: `Met de Lees! Cadeaukaart wordt het spenderen van jouw cadeau zowel online als in fysieke winkels een fluitje van een cent. Deze flexibele cadeaukaart kan opgedeeld worden over meerdere aankopen binnen het uitgebreide assortiment aan tijdschriften, boeken en e-books. Met zo'n divers aanbod vind je gegarandeerd iets dat bij je past, of je nu interesse hebt in thrillers, romans, geschiedenis-, school-, literatuur-, of kookboeken. De Lees! Cadeaukaart biedt toegang tot al deze genres en meer, inclusief kunst- en cultuurboeken, reis- en taalgidsen, stripboeken, sportboeken, en titels over natuur en wetenschap. Gebruik je Lees! Cadeaukaart bij onze partners en ontdek het perfecte leesvoer voor jouw smaak. Daarnaast biedt Lees! Cadeau de kans om het waarde op je cadeaukaart te verdubbelen. Doe mee met onze verdubbelaar op de website en besteed je cadeaukaart in de winkel of online, en wie weet lees je binnenkort dubbel zoveel dankzij Lees! Cadeaukaart.`
};
