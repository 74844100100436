import Link from "next/link";
import NextImage from "next/image";
import Button from "components/shared/button/Button";
import Container from "components/shared/container/Container";

import { useMediaQueryContext } from "lib/mediaQueryContext";
import {
    DoubleStyled,
    Content,
    Left,
    Cards,
    Right,
    Title,
    Text,
    Background
} from "./Double.styled";

export default function Double() {
    const { mobileView, desktopView } = useMediaQueryContext();

    return (
        <DoubleStyled>
            <Container>
                <Content>
                    <Left>
                        <Cards>
                            {mobileView && (
                                <NextImage
                                    src="/images/doubler-mobile.png"
                                    alt="Verdubbelaar"
                                    width={280}
                                    height={255}
                                />
                            )}
                            {desktopView && (
                                <NextImage
                                    src="/images/doubler-desktop.webp"
                                    alt="Verdubbelaar"
                                    width={550}
                                    height={437}
                                />
                            )}
                        </Cards>
                    </Left>
                    <Right>
                        <Title>
                            <strong>Verdubbel</strong> jouw cadeaukaart waarde
                        </Title>
                        <Text>
                            Maak kans op een verdubbeling van het waarde op je
                            Lees! Cadeaukaart. Vul meteen je kaartnummer in en
                            doe mee!
                        </Text>
                        <Link href="/verdubbelaar" passHref prefetch={false}>
                            <Button element="a">Doe mee</Button>
                        </Link>
                    </Right>
                    <Background />
                </Content>
            </Container>
        </DoubleStyled>
    );
}
