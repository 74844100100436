import styled from "styled-components";
import media from "constants/media";

export const UspsStyled = styled.div`
    position: relative;

    margin-top: -110px;
    padding: 160px 0 50px;

    ${media.mdUp`
        padding: 180px 0 125px;
    `}
`;

export const Items = styled.ul`
    display: flex;
    flex-direction: column;

    list-style: none;

    ${media.mdUp`
        flex-direction: row;
        justify-content: space-between;
        margin: 0 -10px;
    `}
`;

export const Item = styled.li`
    flex: 1;
    max-width: 480px;
    margin: 0 auto 35px;

    text-align: center;

    ${media.mdUp`
        margin: 0 10px;

        text-align: left;
    `}
`;

export const Title = styled.h2`
    margin-bottom: 10px;

    font-weight: normal;
    font-size: 20px;
    line-height: 25px;

    ${media.mdUp`
        font-size: 24px;
        line-height: 30px;
    `}

    strong {
        font-weight: normal;
        font-family: var(--font-Agne);
    }
`;

export const Letters = styled.div`
    position: absolute;
    top: 0;
    right: -40px;
    z-index: -1;

    width: 380px;
    height: 892px;

    background: url("/images/home-letters-mobile.svg") no-repeat 35px 5px;

    ${media.mdUp`
        display: none;
    `}
`;

export const Ticker = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 360px;

    margin: 0 auto;
    padding: 20px;

    background: var(--color-white);
    box-shadow: 10px 10px 24px 0 rgba(0, 23, 75, 0.05);

    ${media.mdUp`
        display: none;
    `}
`;
