import styled from "styled-components";
import media from "constants/media";

export const DoubleStyled = styled.div`
    position: relative;

    max-width: 480px;
    margin: 0 auto 50px;

    ${media.mdUp`
        max-width: none;
        margin: 0 auto 120px;
    
    `}
`;

export const Content = styled.div`
    position: relative;

    text-align: center;

    ${media.mdUp`
        display: flex;
        align-items: center;

        text-align: left;

        :before {
            content: url("/images/home-letters-bottom.svg");
            height: 1489px;
            left: -200px;
            position: absolute;
            top: -500px;
            width: 406px;
            z-index: -1;
        }
    `}
`;

export const Left = styled.div`
    margin-bottom: 10px;

    ${media.mdUp`
        width: 50%;
        margin-bottom: 0;
    `}
`;

export const Cards = styled.div`
    display: block;
    width: 100%;
    max-width: 280px;

    margin: 0 auto;

    ${media.mdUp`
        max-width: 550px;
    `}
`;

export const Right = styled.div`
    ${media.mdUp`
        width: 50%;
    `}
`;

export const Title = styled.h2`
    margin-bottom: 14px;

    font-weight: normal;
    font-size: 35px;
    line-height: 40px;

    ${media.mdUp`
        margin-bottom: 24px;

        font-size: 64px;
        line-height: 70px;
    `}

    strong {
        font-weight: normal;
        font-family: var(--font-Agne);
    }
`;

export const Text = styled.p`
    margin-bottom: 24px;

    font-weight: normal;
    font-size: 18px;
    line-height: 25px;

    ${media.mdUp`
        font-size: 20px;
        line-height: 30px;
    `}
`;

export const Background = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: -1;

    width: 469px;
    height: 420px;

    background: url("/images/doubler-x2.svg") no-repeat top center;
    background-size: 469px 280px;

    transform: translate(calc(-72% + 100px), -50%);

    ${media.mdUp`
        background-size: 469px 420px;
        transform: translate(-50%, -50%);
    `}
`;
