import styled from "styled-components";
import media from "constants/media";

export const ShopsStyled = styled.div`
    position: relative;
    z-index: 1;

    margin: 0 auto 50px;
    padding: 45px 10px 100px;
    overflow: hidden;

    color: var(--color-white);

    background: var(--color-peach);

    ${media.mdUp`
        margin: 0 auto 130px;
        padding: 120px 70px 140px;
    `}

    ${media.lgUp`
        padding: 120px 110px 140px;
    `}
`;

export const Content = styled.div`
    position: relative;

    text-align: center;

    ${media.mdUp`
        display: flex;
        align-items: center;
        max-width: none;

        text-align: left;
    `}

    ${media.lgUp`
        display: flex;
        align-items: center;
        max-width: none;

        text-align: left;
    `}
`;

export const Left = styled.div`
    position: relative;
    z-index: 2;

    max-width: 480px;
    margin: 0 auto 24px;

    ${media.mdUp`
        width: 50%;
        margin-bottom: 0;
    `}
`;

export const Title = styled.h2`
    margin-bottom: 14px;

    font-size: 25px;
    font-family: var(--font-DINProCondensed);
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;

    ${media.mdUp`
        margin-bottom: 14px;

        font-size: 32px;
        line-height: 40px;
    `}
`;

export const Text = styled.p`
    margin-bottom: 20px;

    font-size: 14px;
    line-height: 20px;

    ${media.mdUp`
        margin-bottom: 30px;

        font-size: 15px;
        line-height: 25px;
    `}
`;

export const Logos = styled.ul`
    display: flex;
    flex-wrap: wrap;

    align-items: center;
    justify-content: space-around;
    max-width: 480px;

    list-style: none;
`;

export const LogoContainer = styled.li`
    width: 33.3%;
    margin: 10px 0;

    text-align: center;

    ${media.mdUp`
        width: 159px;
        height: 29px;
    `}
`;

export const Logo = styled.img`
    display: block;
    max-width: 100%;
    height: 15px;
    margin: 0 auto;

    ${media.mdUp`
        width: 159px;
        height: 29px;
    `}
`;

export const Names = styled.div`
    position: absolute;
    right: 0;
    bottom: -110px;

    width: 100%;

    ${media.mdUp`
        right: -170px;
        bottom: -160px;

        width: auto;
    `}
`;

export const Top = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;

    list-style: none;

    ${media.mdUp`
        justify-content: flex-end;
        width: 50%;
        margin: 0 -20px 0 auto;
    `}
`;

export const Name = styled.li`
    margin: 0 16px;

    color: var(--color-peach);
    font-weight: normal;
    font-size: 40px;
    font-family: var(--font-Agne);
    line-height: 45px;
    white-space: nowrap;
    text-shadow: 0 0 1px var(--color-white), 0 0 1px var(--color-white),
        0 0 1px var(--color-white), 0 0 1px var(--color-white);

    ${media.mdUp`
        font-size: 85px;
        line-height: 95px;
    `}
`;

export const Bottom = styled.ul`
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 0 -16px;

    list-style: none;

    ${media.mdUp`
        justify-content: flex-end;
    `}
`;
