import dynamic from "next/dynamic";
import { useState } from "react";

import NextImage from "next/image";
import Button from "components/shared/button/Button";
import Container from "components/shared/container/Container";

import {
    GiftStyled,
    Title,
    Text,
    Images,
    Image,
    Usps,
    Usp,
    UspTitle,
    UspText
} from "./Gift.styled";

const ContactFormModal = dynamic(() =>
    import("components/shared/contactForm/modal/Modal")
);

export default function Gift() {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [formType, setFormType] = useState("individual");
    const contentLabel =
        formType === "individual" ? "Neem contact op" : "Offerte aanvragen";

    const openModal = type => {
        setModalIsOpen(true);
        setFormType(type);
    };
    const closeModal = () => setModalIsOpen(false);

    return (
        <GiftStyled>
            <Container>
                <Title>Het perfecte zakelijke geschenk</Title>
                <Text>
                    Geef je relaties of personeel een persoonlijk cadeau met de
                    Lees! Cadeaukaart. Dé cadeaukaart waarmee je de ontvanger
                    letterlijk verrast!
                </Text>
                <Images>
                    <Image>
                        <NextImage
                            src="/images/home-gift-desktop.png"
                            alt="Cadeau verpakkingen"
                            width={760}
                            height={485}
                        />
                    </Image>
                </Images>
                <Usps>
                    <Usp>
                        <UspTitle>
                            Bestel <strong>op factuur</strong>
                        </UspTitle>
                        <UspText>
                            In het bestelproces kan er ook gekozen worden om te
                            bestellen op factuur. Kies in het bestelformulier
                            bij de betaalmethode “op factuur”.
                        </UspText>
                        <Button
                            element="a"
                            variant="ghost"
                            href="/lees-cadeaukaart-bestellen"
                            prefetch={false}
                        >
                            Direct bestellen
                        </Button>
                    </Usp>
                    <Usp>
                        <UspTitle>
                            <strong>Veelzijdige</strong> service
                        </UspTitle>
                        <UspText>
                            Op gebied van zakelijke bestellingen zijn er
                            meerdere mogelijkheden. Hierbij staan wij ook open
                            voor eventuele suggesties vanuit jou. Neem contact
                            op met ons!
                        </UspText>
                        <Button
                            variant="ghost"
                            onClick={() => openModal("individual")}
                        >
                            Neem contact op
                        </Button>
                    </Usp>
                    <Usp>
                        <UspTitle>
                            <strong>Offerte</strong> binnen een uur
                        </UspTitle>
                        <UspText>
                            Je kunt een offerte op maat aanvragen voor jouw
                            bedrijf via het online formulier. Binnen een uur
                            krijg je een passend offerte toegestuurd!
                        </UspText>
                        <Button
                            variant="ghost"
                            onClick={() => openModal("company")}
                        >
                            Offerte aanvragen
                        </Button>
                    </Usp>
                    <Usp>
                        <UspTitle>
                            Extra <strong>personalisatie</strong>
                        </UspTitle>
                        <UspText>
                            Maak jouw cadeau compleet door te personaliseren met
                            jouw logo op de kaart. Of kies een mooie verpakking.
                            Benieuwd? Bekijk dan de mogelijkheden.
                        </UspText>
                        <Button
                            element="a"
                            variant="ghost"
                            href="/lees-cadeaukaart-bestellen"
                            prefetch={false}
                        >
                            Bekijk de mogelijkheden
                        </Button>
                    </Usp>
                </Usps>
            </Container>
            {modalIsOpen && (
                <ContactFormModal
                    openModal={modalIsOpen}
                    contentLabel={contentLabel}
                    type={formType}
                    closeModal={closeModal}
                />
            )}
        </GiftStyled>
    );
}
