import styled from "styled-components";
import media from "constants/media";

export const Above = styled.div`
    position: relative;
    z-index: 2;

    background: var(--color-white);

    ${media.mdUp`
        background: url("/images/home-letters-top.svg") no-repeat 76% 140px;
        background-size: 670px 995px;
    `}
`;
