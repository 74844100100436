import styled from "styled-components";
import media from "constants/media";

export const HeroStyled = styled.div`
    position: relative;

    color: var(--color-white);
    text-align: center;

    background: var(--color-darkNight);

    ${overflowHidden =>
        overflowHidden &&
        media.mdUp`
        overflow: hidden;
    `}
`;

export const Top = styled.div`
    position: relative;
    z-index: 3;

    display: flex;
    justify-content: flex-end;
    padding-top: 15px;

    ${media.desktopOnly}
`;

export const Content = styled.div`
    position: relative;
    z-index: 2;

    max-width: 580px;
    margin: 30px auto 50px;

    ${media.mdUp`
        margin: 80px auto;
    `}
`;
