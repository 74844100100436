import styled from "styled-components";
import media from "constants/media";

export const GiftStyled = styled.div`
    position: relative;
    z-index: 1;

    max-width: 480px;
    margin: 0 auto 50px;

    ${media.mdUp`
        max-width: none;
        margin: 0 auto 130px;
    `}
`;

export const Title = styled.h2`
    max-width: 780px;
    margin: 0 auto 14px;

    font-size: 25px;
    font-family: var(--font-DINProCondensed);
    line-height: 30px;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;

    ${media.mdUp`
        font-size: 32px;
        line-height: 40px;
    `}
`;

export const Text = styled.p`
    max-width: 780px;
    margin: 0 auto 34px;

    font-size: 14px;
    line-height: 20px;
    text-align: center;

    ${media.mdUp`
        margin: 0 auto 40px;

        font-size: 15px;
        line-height: 25px;
    `}
`;

export const Images = styled.div`
    background: url("/images/home-handwritten-mobile.svg") no-repeat center;
    background-size: 405px 385px;

    ${media.mdUp`
        background: url("/images/home-handwritten-desktop.svg") no-repeat center;
        background-size: 922px 405px;
        min-height: 405px;
    `}
`;

export const Image = styled.div`
    position: relative;
    z-index: 1;

    display: block;
    max-width: 280px;
    margin: 0 auto 40px;

    ${media.mdUp`
        max-width: 760px;
    `}
`;

export const Usps = styled.ul`
    list-style: none;

    ${media.mdUp`
        display: flex;
        margin: 0 -10px;
    `}
`;

export const Usp = styled.li`
    margin: 0 0 32px;

    text-align: center;

    ${media.mdUp`
        margin: 0 10px;

        text-align: left;
    `}
`;

export const UspTitle = styled.h3`
    margin-bottom: 10px;

    font-weight: normal;
    font-size: 20px;
    line-height: 25px;

    ${media.mdUp`
        font-size: 24px;
        line-height: 30px;
    `}

    strong {
        font-weight: normal;
        font-family: var(--font-Agne);
    }
`;

export const UspText = styled(Text)`
    margin-bottom: 14px;

    ${media.mdUp`
        margin-bottom: 10px;

        text-align: left;
    `}
`;
