import Link from "next/link";
import Button from "components/shared/button/Button";
import Container from "components/shared/container/Container";

import { resellers } from "helpers/stores";
import {
    ShopsStyled,
    Content,
    Left,
    Title,
    Text,
    Logos,
    LogoContainer,
    Logo,
    Names,
    Top,
    Name,
    Bottom
} from "./Shops.styled";

export default function Shops() {
    const brandsItems =
        resellers?.map(brand => (
            <LogoContainer key={brand.id}>
                <Logo
                    src={brand?.image?.white}
                    alt={`${brand.id} logo`}
                    height={brand?.image?.sizes?.default?.height}
                    width={brand?.image?.sizes?.default?.width}
                    loading="lazy"
                />
            </LogoContainer>
        )) ?? [];

    return (
        <ShopsStyled>
            <Container>
                <Content>
                    <Left>
                        <Title>Verkooppunten</Title>
                        <Text>
                            De Lees! Cadeaukaart kun je ook altijd in de winkel
                            kopen. Aan de hand van jouw postcode, kun je de
                            verkoopppunten bij jou in de buurt vinden.
                        </Text>
                        <Link href="/verkooppunten" passHref prefetch={false}>
                            <Button element="a" variant="secondary">
                                Vind een winkel in de buurt
                            </Button>
                        </Link>
                    </Left>

                    <Logos>{brandsItems}</Logos>
                </Content>
                <Names>
                    <Top>
                        <Name>Bruna</Name>
                        <Name>Jumbo</Name>
                        <Name>Primera</Name>
                    </Top>
                    <Bottom>
                        <Name>The read shop</Name>
                        <Name>Boeken.nl</Name>
                    </Bottom>
                </Names>
            </Container>
        </ShopsStyled>
    );
}
