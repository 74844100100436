import PropTypes from "prop-types";
import NextImage from "next/image";
import Button from "components/shared/button/Button";
import Hero from "components/shared/hero/Hero";
import Description from "components/shared/hero/description/Description";
import { useMediaQueryContext } from "lib/mediaQueryContext";

import { Content, Left, Heading, Right, Background } from "./HomeHero.styled";

function HomeHero({ hero }) {
    const { mobileView, desktopView } = useMediaQueryContext();
    const { title, description } = hero;
    const usps = hero?.usps?.map(usp => usp.title) ?? [];

    return (
        <Hero overflowHidden={false} usps={usps}>
            <Content>
                <Left>
                    <Heading align="left">{title}</Heading>
                    <Description align="left">{description}</Description>
                    <Button
                        element="a"
                        type="button"
                        href="/lees-cadeaukaart-bestellen/"
                    >
                        Bestellen
                    </Button>
                </Left>
                <Right>
                    <Background>
                        {mobileView && (
                            <NextImage
                                src="/images/home-hero-mobile.webp"
                                alt="Lees! Cadeaukaart en magazines"
                                layout="fill"
                                objectFit="contain"
                                quality={100}
                                priority
                            />
                        )}
                        {desktopView && (
                            <NextImage
                                src="/images/home-hero-desktop.webp"
                                alt="Lees! Cadeaukaart en magazines"
                                width={826}
                                height={951}
                                priority
                            />
                        )}
                    </Background>
                </Right>
            </Content>
        </Hero>
    );
}

HomeHero.defaultProps = {
    hero: {
        title: "Letterlijk verrassen",
        description: `Wil jij iemand letterlijk verrassen? Verrassen met een
                        romantisch verhaal, een hip tijdschrift of een spannend
                        e-book? De keuze is geheel aan degene die jij verrast. De Lees! Cadeaukaart kun jij zowel online als in de winkel inwisselen!`,
        usps: []
    }
};

HomeHero.propTypes = {
    hero: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        usps: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string,
                title: PropTypes.string,
                link: PropTypes.string
            })
        )
    })
};

export default HomeHero;
