import styled from "styled-components";
import media from "constants/media";
import NextImage from "next/image";

export const Content = styled.div`
    position: relative;
    z-index: 2;

    width: 100%;
    max-width: 580px;
    margin: 0 auto;
    padding: 20px;

    text-align: center;

    background: var(--color-white);

    ${media.mdUp`
        max-width: 980px;
        padding: 66px 100px 74px;

        box-shadow: 10px 10px 24px 0 rgba(0,23,75,0.05);
    `}
`;

export const Title = styled.h2`
    margin-bottom: 10px;

    font-size: 25px;
    font-family: var(--font-DINProCondensed);
    line-height: 30px;
    letter-spacing: 1px;
    text-transform: uppercase;

    ${media.mdUp`
        margin-bottom: 14px;

        font-size: 32px;
        line-height: 40px;
    `}
`;

export const Text = styled.p`
    margin-bottom: 8px;

    font-size: 14px;
    line-height: 20px;

    ${media.mdUp`
        margin-bottom: 16px;

        font-size: 16px;
        line-height: 25px;
    `}
`;

export const Books = styled.div`
    position: relative;
    z-index: 1;

    min-height: 800px;
    margin: 16px 0 40px;

    ${media.mdUp`
        margin: -100px 0 120px;
    `}
`;

export const Image = styled(NextImage)`
    height: 440px;

    ${media.mdUp`
        height: 800px;
    `}
`;

export const Controls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        &:not(:last-child) {
            margin-right: 30px;
        }
    }
`;
