import styled from "styled-components";
import media from "constants/media";

export const SeoStyled = styled.div`
    max-width: 580px;
    margin: 0 auto 50px;
    padding-top: 50px;

    text-align: center;

    border-top: 1px solid var(--color-lightGrey);

    ${media.mdUp`
        margin: 0 auto 120px;
        padding-top: 0;

        border-top: none;
    `}
`;
